<template>
    <div class="bg-error text-danger mb-4 px-2">
        <div>Sua mensagem não pôde ser entregue.</div>
        <div>Motivo: {{ error.mensagem }}</div>
        <div>Erro: {{ error.code }} </div>
        <a href="https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/" target="_blank">Veja mais aqui</a>
    </div>
</template>

<script>
export default {
    props: [
        'error'
    ],
    methods: {
    },
    data() {
        return {
        }
    }
}
</script>

<style scoped>
    .bg-error{
        background-color: #f1c7cc;
        border-radius: 0.5em;
        margin: 0.5em;
        padding: 0.5em;
    }
</style>